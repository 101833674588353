import { Button } from "@mui/material"

const CLIENT_ID = process.env.REACT_APP_PATREON_CLIENT_ID

const handlePatreonLogin = () => {
  const redirectUri = process.env.REACT_APP_API_URL + "auth/patreon/callback"
  const authUrl = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${redirectUri}`
  window.location.href = authUrl
}

const PatreonButton = () => {
  return (
    <Button variant="contained" color="primary" onClick={handlePatreonLogin}>
      Connect with Patreon
    </Button>
  )
}

export default PatreonButton
