import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography
} from "@mui/material"
import PatreonButton from "components/PatreonButton"
import { ImageDTO } from "dto/imageDto"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Layout from "scenes/layout"
import imageService from "services/image-service"
import userService from "services/user-service"
import { updateUser } from "state/auth"

const ProfilePage = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const [userData, setUserData] = useState(null)
  const [patreonLinkingError, setPatreonLinkingError] = useState(null)
  const [isLoadingProfile, setIsLoadingProfile] = useState(true)
  const [images, setImages] = useState([])
  const [isImagesLoading, setIsImagesLoading] = useState(true)

  const loadProfile = async (userId) => {
    try {
      const res = await userService.getProfile(userId)
      setUserData(res.data.user)
      setIsLoadingProfile(false)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchImages = async (userId, images) => {
    try {
      const res = await imageService.getUserImages()
      const fetchedImages = res.data.images.map((img) => {
        return ImageDTO.create(
          img.id,
          img.userId,
          img.src,
          img.generator,
          img.prompt,
          img.seed,
          img.isUpscaled
        )
      })
      setImages([...images, ...fetchedImages])
      setIsImagesLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleAccessToken = async () => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get("token")

    if (token) {
      try {
        const res = await userService.verifyPatreon(user._id, token)
        setUserData(res.data.user)
        dispatch(updateUser(res.data.user))
      } catch (error) {
        console.error(error)
      }
    }
  }

  const handlePatreonLinkingErrors = () => {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.has("error")) setPatreonLinkingError(true)
  }

  useEffect(() => {
    loadProfile(user._id)
    fetchImages(user._id, images)
    handleAccessToken()
    handlePatreonLinkingErrors()
  }, [user._id])

  return (
    <Layout>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2" align="center" gutterBottom>
                  My Profile
                </Typography>
              </Box>
              <Box>
                {isLoadingProfile ? (
                  <Typography variant="h6" gutterBottom>
                    <CircularProgress size={48} />
                  </Typography>
                ) : (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Username: {userData.username}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                      Email: {userData.email}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2" align="center" gutterBottom>
                  Patreon status
                </Typography>
              </Box>
              {isLoadingProfile ? null : (
                <Box>
                  <Typography variant="h5" mb={3}>
                    {userData.patreonStatus === "UNLINKED"
                      ? "Patreon not linked"
                      : userData.patreonStatus === "SUBSCRIBED"
                      ? "PRO member"
                      : "Unsubscribed"}
                  </Typography>
                  <PatreonButton
                    isSubscribed={userData.isSubscribed === "SUBSCRIBED"}
                  />
                </Box>
              )}
              {patreonLinkingError && (
                <Box mt={2}>
                  <Typography
                    color={"error"}
                    variant="h4"
                    align="center"
                    gutterBottom
                  >
                    Error connecting to Patreon. Reach out to us on Discord for
                    help.
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ py: 8 }}>
          <Grid container spacing={2} wrap="wrap">
            {isImagesLoading ? (
              <CircularProgress />
            ) : (
              images.map((image, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <img
                    src={image.src}
                    alt={image.prompt}
                    style={{ width: "100%" }}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Container>
    </Layout>
  )
}

export default ProfilePage
