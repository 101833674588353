import axios from "axios"

const API_URL = process.env.REACT_APP_API_URL + "auth/"

const signup = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password
  })
}

const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password
    })
    .then((response) => {
      return response.data
    })
}
const authService = {
  signup,
  login
}

export default authService
