import { disableReactDevTools } from "@fvilers/disable-react-devtools"
import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import store from "state/store"
import App from "./App"
import "./index.css"

if (process.env.NODE_ENV === "prod") disableReactDevTools()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
)
