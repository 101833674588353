import {
  Button,
  Container,
  Grid,
  Typography,
  styled,
  useMediaQuery
} from "@mui/material"
import JustifyCenter from "components/JustifyCenter"
import Layout from "scenes/layout"

const StyledBox = styled(Grid)(({ theme, alignRight, isNonMobileScreens }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: alignRight ? "flex-end" : "flex-start",
  flexDirection: isNonMobileScreens ? "row" : "column",
  marginBottom: theme.spacing(2),

  "& img": {
    maxWidth: isNonMobileScreens ? "40%" : "100%",
    maxHeight: isNonMobileScreens ? "40%" : "none"
  },
  "& video": {
    maxWidth: isNonMobileScreens ? "33%" : "83%",
    maxHeight: isNonMobileScreens ? "33%" : "none"
  },
  "& > div": {
    width: isNonMobileScreens ? "" : "80%"
  }
}))

const CenteredGrid = styled(Grid)(({ isNonMobileScreens }) => ({
  margin: "auto",
  maxWidth: isNonMobileScreens ? "60%" : "100%",
  "& > div": {
    paddingLeft: isNonMobileScreens ? "" : "0px !important"
  }
}))

const ProPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width: 820px)")

  return (
    <Layout>
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="h2" component="h1" align="center" gutterBottom>
          Get PRO
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          Upgrade your Cornhub experience with our PRO features and unlock
          exclusive content!
        </Typography>
        <JustifyCenter>
          <Button
            variant="outlined"
            color="primary"
            href="https://www.patreon.com/cornhubai"
          >
            Become a Patron
          </Button>
        </JustifyCenter>
      </Container>
      <CenteredGrid
        isNonMobileScreens={isNonMobileScreens}
        container
        spacing={2}
        display="flex"
      >
        <Grid item xs={12}>
          <StyledBox isNonMobileScreens={isNonMobileScreens} alignRight={false}>
            <img src="/propage/1.png" alt="Pro feature 1" />
            <div>
              <Typography
                variant="h1"
                fontWeight="bold"
                align="left"
                sx={{ mb: 1 }}
              >
                Keep face
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                align="left"
                sx={{ mb: 1 }}
              >
                Keep consistent image generation and create incredible content
              </Typography>
            </div>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox isNonMobileScreens={isNonMobileScreens} alignRight={true}>
            <div>
              <Typography
                align="right"
                variant="h1"
                fontWeight="bold"
                sx={{ mb: 1 }}
              >
                Upscale
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                align="right"
                sx={{ mb: 1 }}
              >
                Upscale an image by 4x to 2048x2048 resolution!
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                align="right"
                sx={{ mb: 1 }}
              >
                + Unlimited generations
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                align="right"
                sx={{ mb: 1 }}
              >
                + Faster generation queue
              </Typography>
            </div>
            <img src="/propage/2.png" alt="Pro feature 2" />
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox isNonMobileScreens={isNonMobileScreens} alignRight={false}>
            <img src="/propage/3.png" alt="Pro feature 3" />
            <div>
              <Typography
                variant="h1"
                fontWeight="bold"
                align="left"
                sx={{ mb: 1 }}
              >
                Edit clothing
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                align="left"
                sx={{ mb: 1 }}
              >
                Change clothing for your models!
              </Typography>
            </div>
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox isNonMobileScreens={isNonMobileScreens} alignRight={true}>
            <div>
              <Typography
                variant="h1"
                fontWeight="bold"
                align="right"
                sx={{ mb: 1 }}
              >
                Edit specific body parts
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                align="right"
                sx={{ mb: 1 }}
              >
                Make it perfect
              </Typography>
            </div>
            <img src="/propage/4.png" alt="Pro feature 4" />
          </StyledBox>
        </Grid>
        <Grid item xs={12}>
          <StyledBox isNonMobileScreens={isNonMobileScreens} alignRight={false}>
            <video
              muted
              loop
              autoPlay
              style={{
                borderRadius: "15px",
                marginLeft: "35px",
                marginRight: "35px"
              }}
            >
              <source src="/propage/53.mp4" type="video/mp4" />
            </video>
            <div>
              <Typography
                variant="h1"
                fontWeight="bold"
                align="left"
                sx={{ mb: 1 }}
              >
                Text to GIF
              </Typography>
              <Typography
                variant="h5"
                fontWeight="bold"
                align="left"
                sx={{ mb: 1 }}
              >
                Coming soon
              </Typography>
            </div>
          </StyledBox>
        </Grid>
      </CenteredGrid>
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography variant="h2" component="h1" align="center" gutterBottom>
          Get PRO
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          Upgrade your Cornhub experience with our PRO features and unlock
          exclusive content!
        </Typography>
        <JustifyCenter>
          <Button
            variant="outlined"
            color="primary"
            href="https://www.patreon.com/cornhubai"
          >
            Become a Patron
          </Button>
        </JustifyCenter>
      </Container>
    </Layout>
  )
}

export default ProPage
