import { Box, useMediaQuery, useTheme } from "@mui/material"
import Layout from "scenes/layout"
import { SignupForm } from "./signupForm"

const SignupPage = () => {
  const theme = useTheme()
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)")
  return (
    <Layout>
      <Box>
        <Box
          width={isNonMobileScreens ? "30%" : "93%"}
          p="2rem"
          m="2rem auto"
          borderRadius="1.5rem"
          backgroundColor={theme.palette.background.alt}
        >
          <SignupForm />
        </Box>
      </Box>
    </Layout>
  )
}

export default SignupPage
