import { Container } from "@mui/material"
import MobileMenu from "components/MobileMenu"
import Navbar from "./navbar/index"

function Layout({ children }) {
  return (
    <>
      <Navbar />
      <Container
        maxWidth="xl"
        sx={{
          py: 2,
          minHeight: "100%",
          "@media (min-width: 960px)": {
            py: 8
          }
        }}
      >
        {children}
      </Container>
      <MobileMenu />
    </>
  )
}

export default Layout
