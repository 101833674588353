import axios from "axios"
import { getToken } from "./token-header"

const API_URL = process.env.REACT_APP_API_URL + "images/"

const getImage = (imageId) => {
  return axios.get(API_URL + imageId, {
    headers: getToken()
  })
}

const getUserImages = () => {
  return axios.get(API_URL + "user", {
    headers: getToken()
  })
}

const imageService = {
  getImage,
  getUserImages
}

export default imageService
