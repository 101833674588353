import { Box, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import io from "socket.io-client"
import Loader from "./Loader"

let API_URL

const LiveFeed = () => {
  API_URL = process.env.REACT_APP_API_URL
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Connect to the WebSocket
    const socket = io(API_URL)

    // Listen for images from the server
    socket.on("images", (images) => {
      setImages(images)
      setLoading(false)
    })

    socket.on("image", (image) => {
      // Add the new image to the beginning of the array
      setImages((prevImages) => [image, ...prevImages.slice(0, 17)])
    })

    return () => {
      // Disconnect from the WebSocket when the component unmounts
      socket.disconnect()
    }
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <Box>
      <Typography variant="h3" mb={3} display={"inline"}>
        LIVE
      </Typography>
      <Typography variant="h2" color="primary" display={"inline"} px={2}>
        C O R N
      </Typography>
      <Typography variant="h3" mb={3} display={"inline"}>
        FEED
      </Typography>
      <Grid container spacing={1} wrap="wrap">
        {images.map((image, index) => (
          <Grid item xs={12} md={2} key={index}>
            <img
              src={image.imageUrl}
              alt={image.prompt}
              style={{ width: "100%" }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default LiveFeed
