import axios from "axios"
import { getToken } from "./token-header"
import Cookies from "js-cookie"

const API_URL = process.env.REACT_APP_API_URL + "profile/"

const getProfile = (id) => {
  return axios.get(API_URL + id, {
    headers: getToken()
  })
}

const verifyPatreon = (id, patreonToken) => {
  const referral = Cookies.get("referral") // Get the referral cookie value

  return axios.post(
    API_URL + "verifyPatreon",
    {
      id,
      patreonToken,
      referral
    },
    {
      headers: getToken()
    }
  )
}

const userService = {
  getProfile,
  verifyPatreon
}

export default userService
