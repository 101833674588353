export const ImageDTO = {
  create: (id, userId, src, generator, prompt, seed, isUpscaled) => {
    return {
      id,
      userId,
      src,
      generator,
      prompt,
      seed,
      isUpscaled
    }
  }
}
