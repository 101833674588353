import store from "state/store"

export const getToken = () => {
  const state = store.getState()
  const token = state.token

  if (token) {
    return { Authorization: `Bearer ${token}` }
  } else {
    return {}
  }
}
