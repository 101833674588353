import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import authService from "services/auth-service.js"
import { setLogin } from "state/auth"
import * as yup from "yup"

const registrationSchema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Required"),
  password: yup.string().required("Required")
})

export function LoginForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { palette } = useTheme()
  const isNonMobile = useMediaQuery("(min-width:600px)")
  const [error, setError] = useState(null)

  const initialValues = {
    values: {
      username: "",
      password: ""
    }
  }

  const onSubmit = async (values) => {
    const { email, password } = values

    try {
      const response = await authService.login(email, password)

      if (response) {
        setError(null)

        dispatch(
          setLogin({
            user: response.user,
            token: response.token
          })
        )
        navigate("/profile")
      }
    } catch (error) {
      if (error && error.response) setError(error.response.data.message)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={registrationSchema}
      validateOnBlur={true}
    >
      {({ errors, touched, handleBlur, handleChange, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }
            }}
          >
            <Typography variant="h2" color="primary">
              Login
            </Typography>
            <TextField
              label="E-mail"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: "span 4" }}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              onBlur={handleBlur}
              onChange={handleChange}
              error={Boolean(touched.password) && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              sx={{ gridColumn: "span 4" }}
            />
          </Box>
          {error && (
            <Box
              mt="1rem"
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
              flexDirection="column"
            >
              <Typography variant="body1" color="error">
                {error}
              </Typography>
            </Box>
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            mt="2rem"
          >
            <Typography>
              Don't have an account?{" "}
              <Link href="/signup" variant="body1">
                Sign up
              </Link>
            </Typography>
            <Button
              type="submit"
              sx={{
                width: "20%",
                backgroundColor: palette.primary.main,
                color: palette.background.default,
                fontWeight: "bold",
                "&:hover": { color: palette.primary.main }
              }}
            >
              LOGIN
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
