import { faCrown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { DarkMode, LightMode, Menu, Person } from "@mui/icons-material"
import {
  Badge,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material"

import FlexBetween from "components/FlexBetween"
import SocialButtons from "components/Socials"
import { MobileMenuContext } from "contexts/MobileMenuContext"
import { Fragment, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setLogout, setMode } from "state/auth"

const Navbar = () => {
  const { setIsActiveMobileMenu } = useContext(MobileMenuContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)")

  const handleLogout = () => {
    dispatch(setLogout())
  }

  const theme = useTheme()
  const dark = theme.palette.neutral.dark
  const primaryLight = theme.palette.primary.light
  const alt = theme.palette.background.alt

  const token = useSelector((state) => state.token)
  const username = useSelector((state) =>
    state.user != null ? state.user.username : null
  )
  const userIsPro = useSelector((state) =>
    state.user != null ? state.user.patreonStatus === "SUBSCRIBED" : false
  )
  const isAuthenticated = token !== null
  const appTitle = "🌽 C O R N H U B"

  return (
    <FlexBetween
      padding="1rem 6%"
      backgroundColor={alt}
      position={"fixed"}
      width={"100%"}
      zIndex={50}
      top={0}
    >
      <FlexBetween gap="1.75rem">
        <Typography
          fontWeight="bold"
          fontSize="clamp(1rem, 2rem, 2.25rem)"
          color="primary"
          onClick={() => navigate("/")}
          children={appTitle}
          sx={{
            "&:hover": {
              color: primaryLight,
              cursor: "pointer"
            }
          }}
        ></Typography>
      </FlexBetween>
      {isNonMobileScreens && (
        <FlexBetween>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/generate")}
            sx={{ mr: 2 }}
          >
            Generate
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/pro")}
            sx={{ fontWeight: 700 }}
          >
            GET PRO
          </Button>
        </FlexBetween>
      )}
      {isNonMobileScreens ? (
        <FlexBetween gap="1rem">
          <SocialButtons />
          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkMode sx={{ fontSize: "25px" }} />
            ) : (
              <LightMode sx={{ color: dark, fontSize: "25px" }} />
            )}
          </IconButton>
          {isAuthenticated ? (
            <Fragment>
              <IconButton onClick={() => navigate("/profile")}>
                <Person sx={{ fontSize: "25px" }} />
              </IconButton>
              {userIsPro ? (
                <Badge
                  badgeContent={
                    <FontAwesomeIcon
                      icon={faCrown}
                      style={{ color: "#FFD700" }}
                    />
                  }
                  color="default"
                >
                  <Typography variant="subtitle1">{username}</Typography>
                </Badge>
              ) : (
                <Typography variant="subtitle1">{username}</Typography>
              )}
              <Button variant="outlined" color="neutral" onClick={handleLogout}>
                Logout
              </Button>
            </Fragment>
          ) : (
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          )}
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => {
            setIsActiveMobileMenu(true)
          }}
        >
          <Menu />
        </IconButton>
      )}
    </FlexBetween>
  )
}

export default Navbar
