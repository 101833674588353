import axios from "axios"
import { getToken } from "./token-header"

const API_URL = process.env.REACT_APP_API_URL + "generate"

const generate = (userId, prompt, generatorId) => {
  return axios.post(
    API_URL + "/new",
    {
      userId,
      prompt,
      generatorId
    },
    {
      headers: {
        "Content-Type": "application/json",
        ...getToken()
      }
    }
  )
}

const regenerate = (userId, prompt, generatorId, imageId) => {
  return axios.post(
    API_URL + "/regenerate",
    {
      userId,
      prompt,
      generatorId,
      imageId
    },
    {
      headers: {
        "Content-Type": "application/json",
        ...getToken()
      }
    }
  )
}

const upscale = (userId, prompt, generatorId, imageId) => {
  return axios.post(
    API_URL + "/upscale",
    {
      userId,
      prompt,
      generatorId,
      imageId
    },
    {
      headers: {
        "Content-Type": "application/json",
        ...getToken()
      }
    }
  )
}

const generateService = {
  generate,
  regenerate,
  upscale
}

export default generateService
