import { CssBaseline, ThemeProvider } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import MobileMenuProvider from "components/MobileMenuProvider"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import GeneratePage from "scenes/generatePage"
import HomePage from "scenes/homePage"
import LoginPage from "scenes/loginPage"
import ProPage from "scenes/proPage"
import ProfilePage from "scenes/profilePage"
import SignupPage from "scenes/signupPage"
import { themeSettings } from "./theme"

function App() {
  const mode = useSelector((state) => state.mode)
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode])
  const isAuth = Boolean(useSelector((state) => state.token))

  return (
    <div className="App">
      <BrowserRouter>
        <MobileMenuProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<HomePage />}></Route>
              <Route path="/signup" element={<SignupPage />}></Route>
              <Route path="/login" element={<LoginPage />}></Route>
              <Route
                path="/profile"
                element={isAuth ? <ProfilePage /> : <Navigate to="/login" />}
              ></Route>
              <Route path="/pro" element={<ProPage />}></Route>
              <Route
                path="/generate"
                element={isAuth ? <GeneratePage /> : <Navigate to="/login" />}
              ></Route>
            </Routes>
          </ThemeProvider>
        </MobileMenuProvider>
      </BrowserRouter>
    </div>
  )
}

export default App
