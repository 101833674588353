import { Box, CircularProgress } from "@mui/material"

const Loader = () => {
  return (
    <Box
      display={"flex"}
      height={"100%"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress />
    </Box>
  )
}

export default Loader
