import patreonStatus from "../constants/patreonStatus.js"
const { Link, useTheme } = require("@mui/material")

const GetProLink = (user) => {
  const theme = useTheme()

  if (user.patreonStatus !== patreonStatus.SUBSCRIBED) {
    return (
      <Link
        style={{ color: theme.palette.primary.main }}
        component={Link}
        href="/pro"
      >
        Get PRO
      </Link>
    )
  } else {
    return null
  }
}

export default GetProLink
