import { MobileMenuContext } from "contexts/MobileMenuContext"
import { useState } from "react"

const MobileMenuProvider = ({ children }) => {
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false)

  return (
    <MobileMenuContext.Provider
      value={{
        isActiveMobileMenu: isActiveMobileMenu,
        setIsActiveMobileMenu: setIsActiveMobileMenu
      }}
    >
      {children}
    </MobileMenuContext.Provider>
  )
}

export default MobileMenuProvider
