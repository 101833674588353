import { ToggleButton, Typography } from "@mui/material"

const TagButton = ({ tag, isSelected, onClick }) => {
  return (
    <ToggleButton
      value={tag.prompt}
      selected={isSelected}
      onClick={() => onClick(tag.prompt)}
      color={isSelected ? "primary" : "standard"}
      sx={{
        mr: 1,
        mb: 1,
        p: 2,
        height: 3,
        bgcolor: "transparent"
      }}
    >
      <Typography noWrap>{tag.name}</Typography>
    </ToggleButton>
  )
}

export default TagButton
