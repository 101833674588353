const generators = [
  {
    value: "realisticDefault",
    label: "📷 REALISTIC",
    isProModel: false
  },
  {
    value: "realisticMissionary",
    label: "📷 Realistic - 🥵 Missionary",
    isProModel: false
  },
  {
    value: "realisticDoggy",
    label: "📷 Realistic - 🐶 Doggystyle POV",
    isProModel: false
  },
  {
    value: "realisticBlowjob",
    label: "📷 Realistic - 🍆 Blowjob",
    isProModel: false
  },
  {
    value: "realisticTentacles",
    label: "📷 Realistic - 🐙 Tentacles",
    isProModel: false
  },
  {
    value: "animeDefault",
    label: "🌸 ANIME",
    isProModel: false
  },
  {
    value: "animeBlowjob",
    label: "🌸 Anime - 🍆 Blowjob",
    isProModel: false
  },
  {
    value: "animeMissionary",
    label: "🌸 Anime - 🥵 Missionary",
    isProModel: false
  },
  {
    value: "animeTentacles",
    label: "🌸 Anime - 🐙 Tentacles",
    isProModel: false
  },
  {
    value: "animePastelDefault",
    label: "🌺 PASTEL ANIME",
    isProModel: true
  },
  {
    value: "animePastelBlowjob",
    label: "🌺 Pastel Anime - 🍆 Blowjob",
    isProModel: true
  },
  {
    value: "animePastelMissionary",
    label: "🌺 Pastel Anime - 🥵 Missionary",
    isProModel: true
  },
  {
    value: "cartoonDefault",
    label: "📺 CARTOON",
    isProModel: true
  },
  {
    value: "cartoonBlowjob",
    label: "📺 Cartoon - 🍆 Blowjob",
    isProModel: true
  },
  {
    value: "cartoonMissionary",
    label: "📺 Cartoon - 🥵 Missionary",
    isProModel: true
  },
  {
    value: "animeInkDefault",
    label: "✒️ INK ANIME",
    isProModel: true
  },
  {
    value: "animeInkBlowjob",
    label: "✒️ Ink Anime - 🍆 Blowjob",
    isProModel: true
  },
  {
    value: "animeInkMissionary",
    label: "✒️ Ink Anime - 🥵 Missionary",
    isProModel: true
  },
  {
    value: "animeSoftDefault",
    label: "🎀 SOFT ANIME",
    isProModel: true
  },
  {
    value: "animeSoftBlowjob",
    label: "🎀 Soft Anime - 🍆 Blowjob",
    isProModel: true
  },
  {
    value: "animeSoftMissionary",
    label: "🎀 Soft Anime - 🥵 Missionary",
    isProModel: true
  },
  {
    value: "artisticDefault",
    label: "🎨 ARTISTIC",
    isProModel: true
  }
]

export default generators
