import { ChevronRight, DarkMode, LightMode, Person } from "@mui/icons-material"
import {
  Box,
  Button,
  Divider,
  IconButton,
  SwipeableDrawer,
  Typography,
  styled,
  useTheme
} from "@mui/material"

import { MobileMenuContext } from "contexts/MobileMenuContext"
import { useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setLogout, setMode } from "state/auth"
import SocialButtons from "./Socials"

const MobileMenu = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const theme = useTheme()
  const { isActiveMobileMenu, setIsActiveMobileMenu } =
    useContext(MobileMenuContext)

  const handleLogout = () => {
    dispatch(setLogout())
  }

  const dark = theme.palette.neutral.dark

  const token = useSelector((state) => state.token)
  const username = useSelector((state) =>
    state.user != null ? state.user.username : null
  )
  const isAuthenticated = token !== null

  const FlexDirection = styled(Box)(({ column }) => ({
    width: "100%",
    display: "flex",
    flexDirection: column ? "column" : "row",
    alignItems: "center",
    padding: "10px",
    "& > div > a": {
      minWidth: "0px"
    }
  }))

  return (
    <SwipeableDrawer
      anchor="right"
      open={isActiveMobileMenu}
      onOpen={() => setIsActiveMobileMenu(true)}
      onClose={() => setIsActiveMobileMenu(false)}
    >
      <FlexDirection sx={{ justifyContent: "space-between" }}>
        <IconButton>
          <ChevronRight onClick={() => setIsActiveMobileMenu(false)} />
        </IconButton>
        <IconButton onClick={() => dispatch(setMode())}>
          {theme.palette.mode === "dark" ? (
            <DarkMode sx={{ fontSize: "25px", textAlign: "right" }} />
          ) : (
            <LightMode
              sx={{ color: dark, fontSize: "25px", textAlign: "right" }}
            />
          )}
        </IconButton>
      </FlexDirection>
      <Typography
        sx={{ ml: 2 }}
        variant="h5"
        component="h1"
        align="left"
        gutterBottom
      >
        Your profile
      </Typography>
      <FlexDirection column={false}>
        {isAuthenticated ? (
          <FlexDirection
            sx={{ justifyContent: "space-between", padding: "0px" }}
          >
            <FlexDirection sx={{ padding: "0px" }}>
              <IconButton onClick={() => navigate("/profile")}>
                <Person sx={{ fontSize: "25px" }} />
              </IconButton>
              <Typography
                variant="subtitle1"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  minWidth: "100px",
                  maxWidth: "100px"
                }}
              >
                {username}
              </Typography>
            </FlexDirection>
            <Button
              variant="outlined"
              color="neutral"
              sx={{ width: "max-content" }}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </FlexDirection>
        ) : (
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => navigate("/login")}
            sx={{ width: "100%" }}
          >
            Login
          </Button>
        )}
      </FlexDirection>
      <Divider />
      <FlexDirection column={true}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/pro")}
          sx={{ fontWeight: 700, m: 1, width: "100%" }}
        >
          GET PRO
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate("/generate")}
          sx={{ m: 1, width: "100%" }}
        >
          Generate
        </Button>
      </FlexDirection>
      <Typography
        sx={{ ml: 2 }}
        variant="h5"
        component="h1"
        align="left"
        gutterBottom
      >
        Social media
      </Typography>
      <FlexDirection>
        <SocialButtons />
      </FlexDirection>
    </SwipeableDrawer>
  )
}

export default MobileMenu
