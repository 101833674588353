import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  ToggleButtonGroup,
  Typography
} from "@mui/material"
import GetProLink from "components/GetProLink"
import TagButton from "components/TagButton"
import featureType from "constants/featureType"
import generators from "data/generators"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Layout from "scenes/layout"
import generateService from "services/generate-service"
import patreonStatus from "../../constants/patreonStatus"
import tags from "../../data/tags.json"

const GeneratePage = () => {
  const navigate = useNavigate()
  let user = useSelector((state) => state.user)
  const [selectedTags, setSelectedTags] = useState({
    Base: "woman",
    "Number of people": "one",
    Clothing: ["nude"]
  })
  const [selectedGenerator, setSelectedGenerator] = useState("realisticDefault")
  const [isLoading, setIsLoading] = useState(false)
  const [imageDTO, setimageDTO] = useState(null)
  const [generateStatus, setGenerateStatus] = useState(
    "Choose your tags and click generate!"
  )
  const [noMoreGeneration, setNoMoreGeneration] = useState(false)

  const handleGeneratorSelectChange = (event) => {
    setSelectedGenerator(event.target.value)
  }

  const handleSubmit = async (feature) => {
    if (
      (feature === featureType.REGENERATE || feature === featureType.UPSCALE) &&
      user.patreonStatus !== patreonStatus.SUBSCRIBED
    ) {
      navigate("/pro")
      return
    }

    const userId = user._id
    const prompt = selectedTags
    const generatorId = selectedGenerator
    const imageId = imageDTO?.id

    setIsLoading(true)
    setGenerateStatus("Generating... It usually takes 10-20 seconds.")
    setimageDTO(null)

    let response

    try {
      switch (feature) {
        case featureType.GENERATE:
          response = await generateService.generate(userId, prompt, generatorId)
          break
        case featureType.REGENERATE:
          response = await generateService.regenerate(
            userId,
            prompt,
            generatorId,
            imageId
          )
          break
        case featureType.UPSCALE:
          response = await generateService.upscale(
            userId,
            prompt,
            generatorId,
            imageId
          )
          break
        default:
          break
      }

      if (!response.data.imageDTO) {
        throw new Error("Something went wrong. Please try again.")
      }
      setimageDTO(response.data.imageDTO)
      setGenerateStatus("")
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      if (
        error.response &&
        error.response.data.error ===
          "User has exceeded maximum generation count."
      ) {
        setGenerateStatus("You have ran out of free account generations.")
        setNoMoreGeneration(true)
      } else {
        console.error(error)
        setGenerateStatus("Something went wrong. Please try again.")
      }
    }
  }

  // Define the component that will render the tags list for each category
  const TagCategory = ({ category, selectedTags }) => {
    const { name, isSingleChoice } = category
    const [selected, setSelected] = useState(null)

    const handleTagButtonClick = (tag) => {
      if (isSingleChoice) {
        setSelected(tag)
        setSelectedTags({ ...selectedTags, [name]: tag })
      } else {
        const currentTags = selectedTags[name]
        let updatedTags
        if (currentTags) {
          updatedTags = currentTags.includes(tag)
            ? currentTags.filter((t) => t !== tag)
            : [...currentTags, tag]
        } else {
          updatedTags = [tag]
        }

        setSelectedTags({ ...selectedTags, [name]: updatedTags })
      }
    }

    return (
      <Box my={2}>
        <Typography variant="h3" mb={1}>
          {name}
        </Typography>
        <ToggleButtonGroup
          exclusive={isSingleChoice}
          onChange={(event, value) =>
            setSelectedTags({ ...selectedTags, [name]: value ?? [] })
          }
          value={isSingleChoice ? selectedTags[name] : selectedTags[name] ?? []}
          className="tagGroup"
        >
          {category.tags.map((tag) => (
            <TagButton
              key={tag.name}
              tag={tag}
              isSelected={
                isSingleChoice
                  ? selectedTags[name] === tag.prompt
                  : selectedTags[name]?.includes(tag.prompt)
              }
              onClick={handleTagButtonClick}
            />
          ))}
        </ToggleButtonGroup>
      </Box>
    )
  }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ py: 8, pb: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <FormControl fullWidth={true}>
                <InputLabel id="generatorSelectLabel">Generator</InputLabel>
                <Select
                  label="Generator"
                  labelId="generatorSelectLabel"
                  value={selectedGenerator}
                  onChange={handleGeneratorSelectChange}
                >
                  {generators.map((generator) => (
                    <MenuItem
                      key={generator.value}
                      value={generator.value}
                      disabled={
                        generator.isProModel &&
                        user.patreonStatus !== patreonStatus.SUBSCRIBED
                      }
                      className={
                        generator.isProModel &&
                        user.patreonStatus !== patreonStatus.SUBSCRIBED
                          ? "locked-pro-item"
                          : ""
                      }
                    >
                      {generator.label}
                      {generator.isProModel &&
                        user.patreonStatus !== patreonStatus.SUBSCRIBED && (
                          <Typography
                            variant="button"
                            className="locked-pro-feature"
                          >
                            PRO FEATURE
                          </Typography>
                        )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {tags.categories.map((category) => (
                <TagCategory
                  key={category.name}
                  category={category}
                  tags={category.tags}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                />
              ))}
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            spacing={2}
            id="preview"
            sx={{
              position: "sticky",
              top: "6rem",
              maxHeight: "calc(100vh - 96px)"
            }}
          >
            <Paper
              elevation={3}
              variant="outlined"
              sx={{
                width: "512px",
                height: "512px"
              }}
            >
              {imageDTO ? (
                <img
                  src={imageDTO.src}
                  alt="preview"
                  style={{
                    borderBottomLeftRadius: 4,
                    borderBottomRightRadius: 4,
                    display: "block",
                    width: "100%"
                  }}
                />
              ) : (
                <Box
                  width={"100%"}
                  height={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant="h5">{generateStatus}</Typography>
                  {noMoreGeneration ? (
                    <Typography variant="h4">
                      <GetProLink user={user} />
                    </Typography>
                  ) : (
                    ""
                  )}
                  {isLoading ? (
                    <Box mt={1}>
                      <Box display="flex" alignItems="center">
                        {user.patreonStatus !== patreonStatus.SUBSCRIBED && (
                          <>
                            <Typography variant="body1" mr={1}>
                              Want faster results?
                            </Typography>
                            <GetProLink user={user} />
                          </>
                        )}
                      </Box>
                      <Box display={"flex"} justifyContent={"center"} mt={3}>
                        <CircularProgress size={48} />
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Box className="generate-footer" p={3}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mr: 2, fontWeight: "bold" }}
          onClick={() => handleSubmit(featureType.GENERATE)}
          disabled={isLoading || noMoreGeneration}
        >
          Generate
        </Button>
        {imageDTO && (
          <>
            <Button
              variant="contained"
              color="info"
              size="large"
              sx={{ mr: 2, fontWeight: "bold" }}
              onClick={() => handleSubmit(featureType.REGENERATE)}
              disabled={isLoading}
            >
              Reimagine
            </Button>
            <Button
              variant="contained"
              color="warning"
              size="large"
              sx={{ fontWeight: "bold" }}
              onClick={() => handleSubmit(featureType.UPSCALE)}
              disabled={isLoading || imageDTO.isUpscaled}
            >
              Upscale
            </Button>
          </>
        )}
      </Box>
    </Layout>
  )
}

export default GeneratePage
