import LiveFeed from "components/LiveFeed"
import Cookies from "js-cookie"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Layout from "scenes/layout"

const HomePage = () => {
  const location = useLocation()

  // https://cornhub.ai/?ref=advertiser1
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const referral = query.get("ref")

    if (referral) {
      Cookies.set("referral", referral, { expires: 30, path: "/" })
    }
  }, [location.search])

  return (
    <Layout>
      <LiveFeed />
    </Layout>
  )
}

export default HomePage
