import { Box } from "@mui/material"
import { styled } from "@mui/system"

const JustifyCenter = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
})

export default JustifyCenter
